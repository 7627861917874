import ethProvider from 'eth-provider';
let provider;
if (window.ethereum && window.ethereum.isFrame) {
    console.log('Using injected provider via window.ethereum', {
        connected: window.ethereum.connected
    });
    provider = window.ethereum;
}
else {
    console.log('Using native connection to Frame via eth-provider');
    provider = ethProvider('frame', { origin: 'send.frame.eth' });
}
export default provider;
