import provider from '../provider';
import defaultChains from '../../store/state/chains.json';
import chainAssets from '../../App/chainAssets';
const jsonApi = {
    // newer endpoint
    getEthereumChains: async () => {
        const chains = await provider.request({
            method: 'wallet_getEthereumChains'
        });
        return chains.map(fromRpcChain);
    },
    // legacy endpoint
    getChains: async () => {
        const chains = await provider.request({
            method: 'wallet_getChains'
        });
        return chains.map((c) => parseInt(c, 16));
    }
};
function getColorForChain(chainId) {
    return chainAssets[chainId]
        ? chainAssets[chainId].primaryColor
        : 'var(--moon)';
}
export async function fetchChains() {
    try {
        console.log('GET ETHERUEM CHAINS');
        // try newer version of chain info first
        const chains = await jsonApi.getEthereumChains();
        return { walletChainsEnabled: true, chains };
    }
    catch (e) {
        const message = e.message || '';
        if (message.toLowerCase() === 'no frame account selected') {
            // no access is allowed to chains if the user has not yet given permission
            return { walletChainsEnabled: true, chains: [] };
        }
        const chains = await getChainsForIds(jsonApi.getChains());
        return { walletChainsEnabled: false, chains };
    }
}
export function ChainsChangedListener(handler) {
    return async function (connectedChains) {
        // backwards compatibility with legacy event
        if (connectedChains.length > 0 && typeof connectedChains[0] === 'string') {
            const chainIds = connectedChains.map((c) => parseInt(c, 16));
            const chains = await getChainsForIds(Promise.resolve(chainIds));
            return handler(chains);
        }
        const chains = connectedChains.map(fromRpcChain);
        handler(chains);
    };
}
function fromRpcChain(chain) {
    const { chainId, name, nativeCurrency } = chain;
    const { name: currencyName } = nativeCurrency;
    const colors = chain?.external?.wallet?.colors || [];
    const primaryColor = colors[0]?.hex || getColorForChain(chainId);
    return {
        chainId,
        name,
        nativeCurrency: {
            name: currencyName
        },
        primaryColor
    };
}
let knownChainInfo = [];
async function loadKnownChains() {
    if (knownChainInfo.length > 0) {
        // only load once per session
        return knownChainInfo;
    }
    try {
        const response = await fetch('https://chainid.network/chains.json');
        if (response.status === 200) {
            knownChainInfo = (await response.json());
            return knownChainInfo;
        }
        const body = await response.text();
        console.warn('could not load known chains, using defaults', {
            status: response.status,
            body
        });
        return defaultChains;
    }
    catch (e) {
        console.warn('could not load known chains, using defaults', e);
        return defaultChains;
    }
}
async function getChainsForIds(fetchChains) {
    const [knownChains, chainIds] = await Promise.all([
        loadKnownChains(),
        fetchChains
    ]);
    return mapIdsToKnownChains(chainIds, knownChains);
}
function mapIdsToKnownChains(chainIds, knownChains) {
    return chainIds.map((activeChainId) => {
        const chain = knownChains.find((knownChain) => knownChain.chainId === activeChainId);
        const chainName = chain ? chain.name : `Chain ${activeChainId}`;
        const nativeCurrency = chain
            ? { name: chain.nativeCurrency.name }
            : { name: 'Native Currency' };
        return {
            chainId: activeChainId,
            nativeCurrency,
            name: chainName,
            primaryColor: getColorForChain(activeChainId)
        };
    });
}
