import Restore from 'react-restore';
import state from './state';
import { setLoadedIn, setWalletChainsEnabled, setLoadingAssets, setRecipient, setRecipientType, setAmount, setGas, setTransactionHash, setTransactionFail, selectCurrency, activateGlitch, enterKey, reset, restart, Feature } from './state/app';
import { advance, setAdvancing, reverse, setReversing, setResolvingRecipient, navigateToStep, cancelNavigation } from './state/nav';
import { setChains } from './state/chains';
import { setBalances } from './state/balances';
import { setAccount, clearAccount } from './state/account';
import { notifyError, notifyMessage } from './state/notify';
const actions = {
    reset,
    restart,
    activateGlitch,
    enterKey,
    setLoadedIn,
    setLoadingAssets,
    setWalletChainsEnabled,
    setRecipient,
    setRecipientType,
    setAmount,
    setGas,
    setTransactionHash,
    setTransactionFail,
    selectCurrency,
    advance,
    setAdvancing,
    reverse,
    setReversing,
    setResolvingRecipient,
    navigateToStep,
    cancelNavigation,
    notifyError,
    notifyMessage,
    setChains,
    setBalances,
    setAccount,
    clearAccount
};
const store = Restore.create(state(), actions);
// typed state access functions
store.getFlowId = () => store('flowId');
store.getAppState = () => store('send');
store.isLoadedIn = () => store('send.loadedIn');
store.isLoadingAssets = () => store('send.loadingAssets');
store.isWalletChainsEnabled = () => store('send.enabledFeatures').includes(Feature.WalletChains);
store.getAmount = () => store('send.amount');
store.getGas = () => store('send.gas');
store.getRecipient = () => store('send.recipient');
store.getRecipientType = () => store('send.recipientType');
store.getTransactionHash = () => store('send.txHash');
store.didTransactionFail = () => store('send.txFail');
store.getSelectedCurrency = () => store('send.selectedCurrency');
store.isGlitching = () => store('glitchOn');
store.isEnterKeyPressed = () => store('enterKey');
store.getNav = () => store('nav');
store.getCurrentStep = () => store('nav.currentStep');
store.isResolvingRecipient = () => store('nav.resolvingRecipient');
store.getNotify = () => store('notify');
store.getChains = () => store('chains') || {};
store.getBalances = () => store('balances') || [];
store.getCurrentAccount = () => store('currentAccount');
export default store;
