import React from 'react';
import Restore from 'react-restore';
import BigNumber from 'bignumber.js';
import { addHexPrefix } from 'ethereumjs-util';
import { parseUnits, formatUnits } from 'ethers/lib/utils';
import provider from '../../../js/provider';
import { resolve as resolveEns } from '../../../js/ens';
import scrollbar from '../../scrollbar';
import { submit as submitTransaction } from '../../tx';
import { validateRecipient } from '../../validation';
import { getGasFees } from '../../gas';
import AssetComponent from '../Asset';
import EnterRecipient from '../EnterRecipient';
import EnterAmount from '../EnterAmount';
import Scroller from '../Scroller';
import InputBox from '../InputBox';
const scrollBarWidth = scrollbar.width;
const tokenItemHeight = 70;
const SEND_GAS_COST = new BigNumber(21000);
const AssetWithChainData = (props) => {
    const chainName = getNameForChain(props.chains, props.asset.chainId);
    return React.createElement(AssetComponent, { ...props, chainName: chainName });
};
function getNameForChain(chains, chainId) {
    const chain = chains.find((chain) => chain.chainId === chainId);
    return chain ? chain.name : `chain ${chainId}`;
}
function getColorForChain(chains, chainId) {
    const chain = chains.find((chain) => chain.chainId === chainId);
    const colorSource = chain || { primaryColor: 'var(--moon)' };
    return colorSource.primaryColor;
}
class _Send extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.navigatingToStep = -1;
        this.moduleWrapRef = React.createRef();
        this.stepTransitionHandlers = {
            1: this.stepOne.bind(this),
            2: this.stepTwo.bind(this),
            3: this.stepThree.bind(this),
            4: this.stepFour.bind(this),
            5: this.stepFive.bind(this)
        };
        this.state = {
            selectedAssetIndex: 0,
            arrowUpActive: false,
            arrowDownActive: false,
            headNod: false,
            headShake: false,
            fullAddressHover: false,
            fullAddressCopy: false,
            loading: true
        };
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.handleKeyUp = this.handleKeyUp.bind(this);
    }
    selectAsset(index) {
        this.setState({ selectedAssetIndex: index });
        setTimeout(() => {
            this.store.advance();
        }, 200);
    }
    handleKeyPress(e) {
        // these arrow presses only apply for selecting a token
        if (this.store.getCurrentStep() === 0) {
            if (e.code === 'ArrowUp') {
                e.preventDefault();
                e.stopPropagation();
                this.setState({
                    arrowUpActive: true,
                    selectedAssetIndex: Math.max(0, this.state.selectedAssetIndex - 1)
                });
            }
            else if (e.code === 'ArrowDown') {
                e.preventDefault();
                e.stopPropagation();
                this.setState({
                    arrowDownActive: true,
                    selectedAssetIndex: Math.min(this.filteredTokens().length - 1, this.state.selectedAssetIndex + 1)
                });
            }
        }
    }
    handleKeyUp(e) {
        // these arrow presses only apply for selecting a token
        if (this.store.getCurrentStep() === 0) {
            if (e.code === 'ArrowUp') {
                e.preventDefault();
                e.stopPropagation();
                this.setState({ arrowUpActive: false });
            }
            else if (e.code === 'ArrowDown') {
                e.preventDefault();
                e.stopPropagation();
                this.setState({ arrowDownActive: false });
            }
        }
    }
    componentDidMount() {
        document.addEventListener('keydown', this.handleKeyPress);
        document.addEventListener('keyup', this.handleKeyUp);
        this.updateGasFees();
        this.loadingTimer = setTimeout(() => {
            // force an initial loading state to prevent flickering as assets are loaded
            this.setState({ loading: false });
        }, 1200);
        this.navigationObserver = this.store.observer(async () => {
            const navigation = this.store.getNav();
            const allowNavigation = !navigation.advancing &&
                !navigation.reversing &&
                navigation.targetStep !== this.navigatingToStep &&
                navigation.targetStep !== navigation.currentStep;
            if (allowNavigation) {
                this.navigatingToStep = navigation.targetStep;
                const validTransition = await (navigation.targetStep >
                    navigation.currentStep
                    ? this.advance(navigation.targetStep)
                    : this.reverse(navigation.currentStep));
                if (validTransition) {
                    this.store.navigateToStep(navigation.targetStep);
                }
                else {
                    this.store.cancelNavigation();
                }
                this.navigatingToStep = -1;
            }
        }, 'send:navigation');
    }
    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyPress);
        document.removeEventListener('keyup', this.handleKeyUp);
        if (this.navigationObserver) {
            this.navigationObserver.remove();
            this.navigationObserver = undefined;
        }
        if (this.loadingTimer) {
            clearTimeout(this.loadingTimer);
            this.loadingTimer = undefined;
        }
        if (this.navigationTimer) {
            clearTimeout(this.navigationTimer);
            this.navigationTimer = undefined;
        }
    }
    onAdvance() {
        this.navigationTimer = setTimeout(() => {
            this.store.setAdvancing(false);
        }, 50);
        this.store.setAdvancing(true);
    }
    onReverse() {
        this.navigationTimer = setTimeout(() => {
            this.store.setReversing(false);
        }, 50);
        this.store.setReversing(true);
    }
    async updateGasFees() {
        this.gasFees = await getGasFees();
    }
    async successEffects() {
        this.store.notifyError();
        this.store.activateGlitch(true);
        this.setState({ headNod: true });
        setTimeout(() => {
            this.setState({ headNod: false });
            this.store.activateGlitch(true);
        }, 1000);
    }
    async stepOne(reverse) {
        if (reverse) {
            this.store.selectCurrency();
            return true;
        }
        else {
            const token = this.filteredTokens()[this.state.selectedAssetIndex];
            if (!token) {
                this.advanceError('No token selected');
                return false;
            }
            this.props.setAmount('');
            this.store.selectCurrency(token);
            const chain = this.store
                .getChains()
                .find((chain) => chain.chainId === token.chainId);
            if (chain?.nativeCurrency.name === token.name) {
                this.updateGasFees();
            }
            return !!token;
        }
    }
    async stepTwo(reverse) {
        if (reverse) {
            this.store.setAmount('');
            return true;
        }
        else {
            try {
                const enteredNumber = new BigNumber(this.props.enteredAmount);
                if (enteredNumber.isNaN())
                    throw new Error('Please enter amount');
                const selectedCurrency = this.store.getSelectedCurrency();
                // the amount in wei
                const rawEnteredAmount = new BigNumber(parseUnits(this.props.enteredAmount, selectedCurrency.decimals).toHexString());
                if (rawEnteredAmount.isGreaterThan(selectedCurrency.balance.raw))
                    throw new Error('Insufficient balance');
                this.store.setAmount(addHexPrefix(rawEnteredAmount.toString(16)));
                return true;
            }
            catch (e) {
                this.advanceError(e.message || 'Invalid amount');
                return false;
            }
        }
    }
    async stepThree(reverse) {
        const addressEntryError = (reason) => {
            this.advanceError(reason);
            return false;
        };
        if (reverse) {
            this.store.setRecipient({ address: '', ens: '' });
            this.store.setRecipientType(undefined);
            return true;
        }
        else {
            const { err, recipient } = validateRecipient(this.props.enteredRecipient);
            if (err) {
                return addressEntryError(err);
            }
            const resolved = await this.resolveRecipient(recipient);
            if (!resolved) {
                return addressEntryError('Could not resolve recipient');
            }
            return true;
        }
    }
    async stepFour(reverse) {
        if (!reverse) {
            this.store.setTransactionFail(false);
            this.store.setTransactionHash('');
            this.submitTx();
        }
        return true;
    }
    async stepFive(reverse) {
        if (!reverse) {
            this.store.restart();
            this.props.onRestart();
        }
        return true;
    }
    async reverse(step) {
        this.onReverse();
        return this.stepTransitionHandlers[step](true);
    }
    async advance(step) {
        this.onAdvance();
        const isValidTransition = await this.stepTransitionHandlers[step](false);
        if (isValidTransition)
            this.successEffects();
        return isValidTransition;
    }
    advanceError(error, persist = false) {
        this.store.notifyError(error, persist);
        this.store.activateGlitch(true);
        this.setState({ headShake: true });
        setTimeout(() => {
            this.setState({ headShake: false });
            this.store.activateGlitch(false);
        }, 1000);
    }
    async resolveRecipient(enteredRecipient) {
        this.store.setResolvingRecipient(true);
        const resolveRecipientDetails = async () => {
            const resolved = await resolveEns(enteredRecipient);
            if (!resolved.address)
                throw new Error('No address found');
            const selectedCurrency = this.store.getSelectedCurrency();
            const request = {
                id: 1,
                jsonrpc: '2.0',
                method: 'eth_getCode',
                params: [resolved.address, 'latest'],
                chainId: '0x' + selectedCurrency.chainId.toString(16)
            };
            const code = await provider.request(request);
            return { resolved, code };
        };
        const resolveWithTimeout = async () => {
            return new Promise((resolve, reject) => {
                setTimeout(reject, 10000);
                resolveRecipientDetails().then(resolve).catch(reject);
            });
        };
        try {
            const { resolved, code } = await resolveWithTimeout();
            this.store.setRecipient(resolved);
            this.store.setRecipientType(code === '0x' ? 'external' : 'contract');
        }
        catch (e) {
            console.error('could not resolve recipient', enteredRecipient, e);
            return false;
        }
        finally {
            setTimeout(() => this.store.setResolvingRecipient(false), 0);
        }
        return true;
    }
    async submitTx() {
        const flowId = this.store.getFlowId();
        try {
            const appState = this.store.getAppState();
            const { recipient, amount } = this.store.getAppState();
            const selectedCurrency = appState.selectedCurrency;
            // safety checks, these should never happen but prevent catastrophic mistakes
            if (!recipient.address) {
                this.store.setTransactionFail(true);
                this.advanceError('transaction was attempted with no recipient. please report this issue in the Frame Discord https://discord.gg/tseMRTnD', true);
                return;
            }
            const txConfig = {
                chainId: selectedCurrency.chainId,
                to: recipient.address,
                from: this.store.getCurrentAccount().address,
                amount
            };
            const gas = this.store.getGas();
            if (gas.gasLimit) {
                txConfig.gasLimit = addHexPrefix(gas.gasLimit.toString(16));
            }
            if (gas.fees) {
                txConfig.maxFeePerGas = addHexPrefix(gas.fees.maxFeePerGas.toString(16));
                txConfig.maxPriorityFeePerGas = addHexPrefix(gas.fees.maxPriorityFeePerGas.toString(16));
            }
            else if (gas.gasPrice) {
                txConfig.gasPrice = addHexPrefix(gas.gasPrice.toString(16));
            }
            let hash;
            if (selectedCurrency.type === 'erc20') {
                const selectedToken = selectedCurrency;
                hash = await submitTransaction({
                    type: 'erc20',
                    contract: selectedToken.contractAddress,
                    ...txConfig
                });
                this.store.setTransactionHash(hash);
            }
            else if (selectedCurrency.type === 'native') {
                hash = await submitTransaction({ type: 'native', ...txConfig });
                this.store.setTransactionHash(hash);
            }
            if (flowId !== this.store.getFlowId())
                return;
        }
        catch (e) {
            if (flowId !== this.store.getFlowId())
                return;
            const errMsg = typeof e !== 'string' && typeof e === 'object'
                ? e.message
                : e;
            this.advanceError(errMsg, true);
            this.store.setTransactionFail(true);
        }
    }
    txSummary() {
        const { recipient, amount, selectedCurrency } = this.store.getAppState();
        const loading = this.isLoading();
        const hoveredToken = this.filteredTokens()[this.state.selectedAssetIndex];
        const { chainId } = selectedCurrency || { chainId: NaN };
        const showToken = chainId ? selectedCurrency : hoveredToken;
        const formatted = amount
            ? formatUnits(amount, selectedCurrency?.decimals || 0)
            : '';
        const displayValue = formatted.slice(0, 14);
        const chainColor = getColorForChain(this.store.getChains(), chainId || hoveredToken?.chainId || NaN);
        const chain = showToken &&
            this.store
                .getChains()
                .find((chain) => chain.chainId === showToken.chainId);
        const chainName = (chain && chain.name) || chainId.toString();
        const chainNameStyle = chainName.length > 22 ? { fontSize: '24px' } : {};
        const highlights = showToken && chainColor ? { color: chainColor } : {};
        const bg = showToken && chainColor ? { background: chainColor } : {};
        return (React.createElement("div", { className: "txSummary" }, loading ? (React.createElement("div", { className: "txDescription" }, 'loading')) : (React.createElement("div", { className: "txDescription" },
            React.createElement("div", null,
                showToken ? React.createElement("div", { className: "txStandard" }, 'Send') : null,
                amount ? (React.createElement("div", { className: "txVariable txVariableMoon txInvert", style: bg }, displayValue)) : null,
                showToken ? (React.createElement("div", { className: "txVariable txVariableMoon", style: highlights }, showToken.symbol)) : null),
            React.createElement("div", null, recipient.address ? (React.createElement("div", { onMouseEnter: () => {
                    this.setState({ fullAddressHover: true });
                }, onMouseLeave: () => {
                    this.setState({ fullAddressHover: false });
                } }, this.state.fullAddressHover || this.state.fullAddressCopy ? (React.createElement("div", { className: "fullTxAddress txInvert", style: bg, onClick: () => {
                    try {
                        navigator.clipboard.writeText(recipient.address);
                        this.setState({ fullAddressCopy: true });
                        setTimeout(() => {
                            this.setState({ fullAddressCopy: false });
                        }, 1800);
                    }
                    catch (e) {
                        this.advanceError('Could not copy address');
                    }
                } }, this.state.fullAddressCopy
                ? 'Address Copied'
                : recipient.address)) : (React.createElement(React.Fragment, null,
                React.createElement("div", { className: "txStandard" }, "to"),
                React.createElement("div", { className: "txVariable txVariableMoon txAddress txInvert", style: bg }, recipient.ens ? (recipient.ens) : (React.createElement(React.Fragment, null,
                    React.createElement("span", { className: "addySlug" }, '0'),
                    React.createElement("span", { className: "addyX" }, 'x'),
                    React.createElement("span", { className: "addySlug" }, recipient.address.substr(2, 4)),
                    React.createElement("span", { className: "squareDot" }),
                    React.createElement("span", { className: "squareDot" }),
                    React.createElement("span", { className: "squareDot" }),
                    React.createElement("span", { className: "addySlug" }, recipient.address.substr(recipient.address.length - 4))))))))) : null),
            React.createElement("div", null, showToken ? (React.createElement(React.Fragment, null,
                React.createElement("div", { className: "txStandard" }, 'on'),
                React.createElement("div", { className: "txVariable txVariableMoon", style: { ...highlights, ...chainNameStyle } }, chainName))) : null)))));
    }
    filteredTokens() {
        const { searchValue } = this.props;
        const assets = this.store.getBalances();
        const chains = this.store.getChains();
        return assets.filter((asset) => {
            const chain = chains.find((chain) => chain.chainId === asset.chainId);
            if (!chain)
                return false;
            if (searchValue === '')
                return true;
            const sv = searchValue.toLowerCase().split(' ');
            return sv.every((v) => {
                // first try to match on token data
                return (chain.name.toLowerCase().includes(v) ||
                    asset.name.toLowerCase().includes(v) ||
                    asset.symbol.toLowerCase().includes(v));
            });
        });
    }
    renderWings() {
        return React.createElement("div", { className: "wingWrap" }, "enter");
    }
    step() {
        const { selectedAssetIndex } = this.state;
        const { searchValue, enteredAmount, enteredRecipient, setAmount, setRecipient, setSearchValue } = this.props;
        const enterKey = this.store.isEnterKeyPressed();
        const { currentStep: stepIndex } = this.store.getNav();
        const { selectedCurrency } = this.store.getAppState();
        const chains = this.store.getChains();
        const filteredTokens = this.filteredTokens();
        const tokenCount = filteredTokens.length;
        const tokenChains = filteredTokens.map((token) => token.chainId);
        const onChains = [...new Set(tokenChains)].length;
        const tokens = filteredTokens.map((asset, i) => {
            const identifier = asset.type === 'erc20'
                ? asset.contractAddress
                : asset.symbol;
            const key = `${identifier}:${asset.chainId}`;
            return (React.createElement(AssetWithChainData, { key: key, asset: asset, chainName: getNameForChain(chains, asset.chainId), chainColor: getColorForChain(chains, asset.chainId), outsideList: false, onClick: () => this.selectAsset(i), chains: chains }));
        });
        if (stepIndex === 0) {
            return (React.createElement(React.Fragment, null,
                React.createElement("div", { "aria-hidden": this.isLoading(), className: "searchDescription", role: "heading" },
                    'You have',
                    React.createElement("span", { className: "searchDescriptionVar" }, tokenCount),
                    tokenCount === 1 ? 'token on' : 'tokens on',
                    React.createElement("span", { className: "searchDescriptionVar" }, onChains),
                    onChains === 1 ? 'chain' : 'chains'),
                React.createElement(Scroller, { selectedIndex: selectedAssetIndex, itemHeight: tokenItemHeight, movingUp: this.state.arrowUpActive, movingDown: this.state.arrowDownActive, setIndex: (index) => this.setState({ selectedAssetIndex: index }) }, tokens),
                React.createElement("div", { className: "searchDescription" },
                    'FILTER BY',
                    React.createElement("span", { className: "searchDescriptionVar" }, 'NAME'),
                    '•',
                    React.createElement("span", { className: "searchDescriptionVar" }, 'SYMBOL'),
                    '•',
                    React.createElement("span", { className: "searchDescriptionVar" }, 'CHAIN')),
                React.createElement("div", { className: "searchBox" },
                    React.createElement(InputBox, { value: searchValue, onChange: (e) => {
                            const target = e.target;
                            this.setState({ selectedAssetIndex: 0 });
                            setSearchValue(target.value);
                        } }))));
        }
        else if (stepIndex === 1) {
            const asset = selectedCurrency;
            const setCustomAmount = (amount) => {
                this.store.setGas({});
                setAmount(amount);
            };
            const setMaxAmount = () => {
                if (asset.type !== 'native') {
                    return setCustomAmount(asset.balance.raw.shiftedBy(-asset.decimals).toString());
                }
                const gas = this.gasFees || {};
                const gasPrice = gas.fees
                    ? gas.fees.maxFeePerGas.plus(gas.fees.maxPriorityFeePerGas)
                    : gas.gasPrice;
                const max = !!gasPrice
                    ? BigNumber.max(0, asset.balance.raw.minus(gasPrice.multipliedBy(SEND_GAS_COST)))
                    : asset.balance.raw;
                this.store.setGas({ ...gas, gasLimit: SEND_GAS_COST });
                setAmount(max.shiftedBy(-asset.decimals).toString());
            };
            return (React.createElement(EnterAmount, { asset: selectedCurrency, enteredAmount: enteredAmount, chainColor: getColorForChain(chains, asset.chainId), showButtons: this.store.isWalletChainsEnabled(), setCustomAmount: setCustomAmount, setMaxAmount: setMaxAmount }));
        }
        else if (stepIndex === 2) {
            return (React.createElement(EnterRecipient, { recipient: enteredRecipient, resolving: this.store.isResolvingRecipient(), setRecipient: setRecipient }));
        }
        else if (stepIndex === 3) {
            return (React.createElement(React.Fragment, null,
                React.createElement("div", { className: "signButtonWrap" },
                    React.createElement("div", { role: "button", className: enterKey ? 'signButton signButtonActive' : 'signButton', onClick: () => {
                            this.store.advance();
                        } }, 'SEND TRANSACTION'))));
        }
        else if (stepIndex === 4) {
            return (React.createElement(React.Fragment, null,
                React.createElement("div", { className: "signButtonWrap" }, this.store.getTransactionHash() ? (React.createElement("div", { className: "signPending signPendingSucessful" }, 'TRANSACTION SENT')) : this.store.didTransactionFail() ? (React.createElement("div", { className: "signPending signPendingFailure" }, 'TRANSACTION FAILED')) : (React.createElement("div", { className: "signPending" }, 'TRANSACTION PENDING')))));
        }
        else {
            return null;
        }
    }
    notices() {
        const { selectedCurrency, recipient, recipientType, amount } = this.store.getAppState();
        const { currentStep } = this.store.getNav();
        const decimals = selectedCurrency ? selectedCurrency.decimals : 0;
        const formatted = amount ? formatUnits(amount, decimals) : '';
        const enteredAmount = new BigNumber(formatted || NaN);
        const convertedAmount = enteredAmount.multipliedBy(selectedCurrency?.price.usd || 0);
        const roundedAmount = convertedAmount.decimalPlaces(0);
        const noticeAmount = `${roundedAmount.toNumber().toLocaleString('en')} USD`;
        if (!selectedCurrency || currentStep === 0)
            return null;
        const chains = this.store.getChains();
        const chainName = getNameForChain(chains, selectedCurrency.chainId);
        const chainColor = getColorForChain(chains, selectedCurrency.chainId);
        return (React.createElement("div", { className: "txNotices" },
            amount && selectedCurrency && currentStep > 2 ? (React.createElement("div", { className: "noticeWrap" },
                React.createElement("span", { className: "txNotice" }, selectedCurrency &&
                    selectedCurrency.symbol &&
                    !enteredAmount.isNaN() ? (React.createElement(React.Fragment, null,
                    React.createElement("span", { className: "noticeVar" }, `${formatted} ${selectedCurrency.symbol}`),
                    convertedAmount.isEqualTo(roundedAmount) ? '=' : '≈',
                    React.createElement("span", { className: "noticeVar" }, noticeAmount))) : null))) : null,
            recipientType && currentStep > 1 ? (React.createElement("div", { className: "noticeWrap" },
                recipientType === 'external' ? (React.createElement("span", { className: "txNotice" },
                    'Recipient is an',
                    React.createElement("span", { className: "noticeVar" }, `external account`))) : recipientType === 'contract' ? (React.createElement("span", { className: "txNotice" },
                    'Recipient is a',
                    React.createElement("span", { className: "noticeVar" }, `smart contract`))) : null,
                React.createElement("div", { className: "noticeAddress" }, recipient.address))) : null,
            React.createElement("div", { className: "noticeWrap" },
                React.createElement(AssetWithChainData, { asset: selectedCurrency, outsideList: true, chainName: chainName, chainColor: chainColor, onClick: () => this.selectAsset(0), chains: chains }))));
    }
    isLoading() {
        return (this.state.loading ||
            !this.store.getCurrentAccount().address ||
            this.store.isLoadingAssets());
    }
    render() {
        const loading = this.isLoading();
        const noAssets = !loading && this.store.getBalances().length === 0;
        const selectedAccount = this.store.getCurrentAccount();
        const accountSelected = !!selectedAccount.address;
        const loadingText = accountSelected
            ? noAssets
                ? 'No Assets Found'
                : 'Discovering Assets'
            : 'Waiting for Account';
        return (React.createElement("div", { className: this.state.headShake
                ? 'moduleWrapCenter headShake'
                : this.state.headNod
                    ? 'moduleWrapCenter headNod'
                    : 'moduleWrapCenter' },
            React.createElement("div", { className: "moduleSlice" },
                React.createElement("div", { className: "moduleWrap", ref: this.moduleWrapRef, style: { minWidth: `calc(100% + ${scrollBarWidth + 50}px)` } },
                    React.createElement("div", { className: "module" },
                        React.createElement("div", { style: loading || noAssets ? { opacity: 0 } : {} },
                            this.txSummary(),
                            this.step(),
                            this.notices()),
                        (loading || noAssets) && (React.createElement("div", { className: "loaderWrap" },
                            React.createElement("div", { className: "loadingAssets" },
                                React.createElement("span", { className: noAssets ? 'noneFound' : 'discovering' }, loadingText)))))))));
    }
}
const Send = Restore.connect(_Send);
export default Send;
