import React from 'react';
import svg from '../../../../../resource/svg';
const Nav = ({ reversing, advancing, currentStep, onRestart, onBack, onForward }) => {
    const isEndOfFlow = currentStep === 4;
    const isBackButtonActive = currentStep > 0;
    const navButton = isEndOfFlow ? (React.createElement("div", { role: "button", className: advancing
            ? 'navButtonForward navButtonForwardGlow navButtonAdvanceActive'
            : 'navButtonForward navButtonForwardGlow', onClick: onRestart },
        React.createElement("span", { className: "enterTextBig" }, 'restart'))) : (React.createElement(React.Fragment, null,
        isBackButtonActive && (React.createElement("div", { role: "button", className: reversing ? 'navButtonBack navButtonAdvanceActive' : 'navButtonBack', onClick: onBack },
            React.createElement("span", { className: "enterIcon", style: { paddingLeft: '2px', paddingTop: '8px' } }, svg.caretLeft(12)))),
        React.createElement("div", { role: "button", className: advancing
                ? 'navButtonForward navButtonAdvanceActive'
                : 'navButtonForward', onClick: onForward },
            React.createElement("span", { className: "enterText" }, 'press'),
            React.createElement("span", { className: "enterTextBig" }, 'enter'),
            React.createElement("span", { className: "enterIcon", style: { paddingLeft: '0px', paddingTop: '8px', marginRight: '-4px' } }, svg.caretRight(12)))));
    return (React.createElement("div", { className: "footer navPanel" },
        React.createElement("div", { className: "versionBadge" },
            React.createElement("span", null, process.env.SEND_DAPP_VERSION)),
        React.createElement("div", { className: "navButton" }, navButton)));
};
export default Nav;
