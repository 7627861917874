const defaultState = () => ({
    flowId: Date.now().toString(),
    chains: [],
    currentAccount: { ens: '', address: '' },
    balances: [],
    enterKey: false,
    notify: {
        message: '',
        error: ''
    },
    nav: {
        currentStep: 0,
        targetStep: 0,
        advancing: false,
        reversing: false,
        resolvingRecipient: false
    },
    send: {
        loadingAssets: false,
        loadedIn: false,
        enabledFeatures: [],
        notices: [],
        recipient: { ens: '', address: '' },
        recipientType: undefined,
        selectedCurrency: undefined,
        amount: '',
        gas: {},
        txHash: ''
    }
});
export default defaultState;
