import React from 'react';
import BigNumber from 'bignumber.js';
import InputBox from '../InputBox';
function formatLabel(text) {
    if (text.length > 8) {
        return {
            label: `${text.substring(0, 4)}...`,
            style: { fontSize: '12px' }
        };
    }
    const style = text.length > 6
        ? { fontSize: '9px' }
        : text.length > 4
            ? { fontSize: '12px' }
            : {};
    return { style, label: text };
}
const AmountInputBox = ({ value, chainColor, maxDecimals, setAmount, label }) => {
    const highlights = chainColor ? { color: chainColor } : {};
    const validateEntry = (e) => {
        const enteredText = e.nativeEvent.data || '';
        const { value: existingText, selectionStart, selectionEnd } = e.target;
        const inputLocation = selectionStart || 0;
        const enteredNumber = new BigNumber(enteredText || '');
        const decimalPosition = existingText.indexOf('.');
        const selectionLength = (selectionEnd || 0) - (selectionStart || 0);
        const addedCharacters = enteredText.length - selectionLength;
        const invalidCharacter = enteredText !== '.' && enteredNumber.isNaN();
        const duplicateDecimal = enteredText.includes('.') && decimalPosition >= 0;
        const tooLong = addedCharacters + existingText.length > 26;
        const tooManyDecimals = decimalPosition >= 0 &&
            inputLocation > decimalPosition &&
            addedCharacters + (existingText.length - 1) - decimalPosition >
                maxDecimals;
        if (invalidCharacter || duplicateDecimal || tooManyDecimals || tooLong) {
            return e.preventDefault();
        }
        if (enteredText === '0' && inputLocation <= decimalPosition) {
            const wholeNumber = existingText.slice(0, decimalPosition);
            if (wholeNumber && (inputLocation === 0 || Number(wholeNumber) === 0))
                return e.preventDefault();
        }
    };
    const formatText = (e) => {
        const enteredText = e.target.value;
        const num = new BigNumber(enteredText);
        if (enteredText === '.')
            return setAmount('.');
        if (num.isNaN())
            return setAmount('');
        const [wholeNumberPart, decimalPart] = enteredText.split('.');
        const wholeNumberStr = wholeNumberPart
            ? new BigNumber(wholeNumberPart).toFormat({})
            : '';
        const decimalStr = enteredText.indexOf('.') >= 0 ? `.${decimalPart}` : '';
        setAmount(wholeNumberStr + decimalStr);
    };
    const { style: labelStyle, label: formattedLabel } = formatLabel(label);
    return (React.createElement("div", { className: "searchBox labeled" },
        React.createElement("div", { role: "label", className: "searchBoxLabel", style: { ...highlights, ...labelStyle } }, formattedLabel),
        React.createElement("div", { className: "searchBoxInputWrap" },
            React.createElement(InputBox, { value: value, maxTextSize: 20, onBeforeInput: validateEntry, onChange: formatText })),
        React.createElement("div", { className: "searchBoxLabel searchBoxLabelRight", style: highlights })));
};
const ConvertedRate = ({ symbol, value, price }) => {
    const convertedTotal = value.multipliedBy(price);
    const roundedTotal = convertedTotal.decimalPlaces(0);
    return (React.createElement("div", { className: "conversionRate" },
        React.createElement("span", { className: "conversionRateNum" },
            value.toFormat(),
            " "),
        React.createElement("span", { className: "conversionRateSymbol" },
            symbol,
            " "),
        React.createElement("span", { className: "conversionRateEq" },
            roundedTotal.isEqualTo(convertedTotal) ? '=' : '≈',
            ' '),
        React.createElement("span", { className: "conversionRateNum" },
            roundedTotal.toNumber().toLocaleString('en'),
            ' '),
        React.createElement("span", { className: "conversionRateSymbol" }, 'USD')));
};
const EnterAmount = ({ asset, enteredAmount, showButtons, chainColor, setCustomAmount, setMaxAmount }) => {
    const enteredValue = new BigNumber(enteredAmount || NaN);
    const { chainId, symbol, decimals } = asset;
    return (React.createElement(React.Fragment, null,
        symbol && (React.createElement(ConvertedRate, { symbol: symbol, value: enteredValue.isNaN() ? new BigNumber(1) : enteredValue, price: asset.price.usd })),
        React.createElement("div", { className: "searchDescription" }, `Set amount to send`),
        React.createElement(AmountInputBox, { value: enteredAmount, label: symbol, chainId: chainId, chainColor: chainColor, maxDecimals: decimals, setAmount: setCustomAmount }),
        showButtons && (React.createElement("div", { className: "inputToolBox" },
            React.createElement("div", { className: "inputToolBoxWrap" },
                React.createElement("div", { role: "button", className: "inputToolBoxButton", onClick: () => setCustomAmount('') }, "Clear"),
                React.createElement("div", { role: "button", className: "inputToolBoxButton", onClick: setMaxAmount }, "Send Max"))))));
};
export default EnterAmount;
