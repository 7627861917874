import state from '.';
import store from '..';
export var Feature;
(function (Feature) {
    Feature[Feature["WalletChains"] = 0] = "WalletChains";
})(Feature || (Feature = {}));
export const setLoadedIn = (u, loadedIn) => {
    if (store.isLoadedIn() === loadedIn)
        return;
    u('send.loadedIn', () => loadedIn);
};
export const setLoadingAssets = (u, loading) => {
    if (store.isLoadingAssets() === loading)
        return;
    u('send.loadingAssets', () => loading);
};
export const setWalletChainsEnabled = (u, enabled) => {
    if (store.isWalletChainsEnabled() === enabled)
        return;
    u('send.enabledFeatures', (features) => {
        if (enabled) {
            return [...features, Feature.WalletChains];
        }
        else {
            const updatedFeatures = features.slice();
            const featureIndex = updatedFeatures.indexOf(Feature.WalletChains);
            updatedFeatures.splice(featureIndex, 1);
            return updatedFeatures;
        }
    });
};
export function setRecipient(u, recipient) {
    u('send.recipient', () => recipient);
}
export function setAmount(u, amount) {
    u('send.amount', () => amount);
}
export function setGas(u, gas) {
    u('send.gas', () => gas);
}
export const setRecipientType = (u, recipientType) => {
    u('send.recipientType', () => recipientType);
};
export function selectCurrency(u, asset) {
    u('send', (send) => ({
        ...send,
        selectedCurrency: asset,
        currency: asset ? asset.symbol : '',
        chain: asset ? asset.chainId : NaN
    }));
}
export function setTransactionHash(u, hash) {
    u('send.txHash', () => hash);
}
export function setTransactionFail(u, fail) {
    u('send.txFail', () => fail);
}
export const activateGlitch = (u, glitch) => {
    if (store.isGlitching() === glitch)
        return;
    u('glitchOn', () => glitch);
};
export const enterKey = (u, enterKey) => {
    if (store.isEnterKeyPressed() === enterKey)
        return;
    u('enterKey', () => enterKey);
};
export const reset = (u) => {
    // clears all data as if the app was just opened, without loading animation
    const initialState = state();
    u(() => ({
        ...initialState,
        send: {
            ...initialState.send,
            loadedIn: true
        }
    }));
};
export const restart = (u) => {
    // sets app back to start of navigation
    const { notify, nav, send } = state();
    u((main) => {
        return {
            ...main,
            notify,
            nav,
            send: {
                ...send,
                loadedIn: main.send.loadedIn,
                enabledFeatures: main.send.enabledFeatures
            }
        };
    });
};
