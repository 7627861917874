import React from 'react';
import InputBox from '../InputBox';
const RecipientInputBox = ({ value, resolving, onChange = () => { } }) => {
    return (React.createElement("div", { className: "searchBox" },
        resolving && (React.createElement("div", { className: "searchBoxInputWrap" },
            React.createElement("div", { className: "resolvingRecipient" },
                React.createElement("div", { className: "resolvingRecipientInner" }, "Resolving Recipient")))),
        React.createElement(InputBox, { value: value, onChange: onChange, hideText: resolving })));
};
const EnterRecipient = ({ recipient, resolving, setRecipient }) => {
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "contactList" }),
        React.createElement("div", { className: "searchDescription" },
            'Enter',
            React.createElement("div", { className: "searchDescriptionVar" }, 'RECIPIENT ADDRESS'),
            'or',
            React.createElement("div", { className: "searchDescriptionVar" }, 'ENS NAME')),
        React.createElement(RecipientInputBox, { value: recipient, resolving: resolving, onChange: (e) => setRecipient(e.target.value) })));
};
export default EnterRecipient;
