// import arbitrum from './arbitrum.svg'
// import eth from './eth.svg'
// import fantom from './fantom.svg'
// import optimism from './optimism.svg'
// import polygon from './polygon.svg'
// import xdai from './xdai.svg'
export default {
    1: {
        chainId: 1,
        name: 'mainnet',
        // icon: eth,
        primaryColor: 'rgb(0, 210, 190)'
    },
    10: {
        chainId: 10,
        name: 'optimism',
        // icon: optimism,
        primaryColor: 'rgb(220, 84, 65)'
    },
    100: {
        chainId: 100,
        name: 'xdai',
        // icon: xdai,
        primaryColor: 'rgb(99, 167, 165)'
    },
    137: {
        chainId: 137,
        name: 'polygon',
        // icon: polygon,
        primaryColor: 'rgb(142, 94, 241)'
    },
    250: {
        chainId: 250,
        name: 'fantom',
        // icon: fantom,
        primaryColor: 'rgb(51, 103, 246)'
    },
    42161: {
        chainId: 42161,
        name: 'arbitrum',
        // icon: arbitrum,
        primaryColor: 'rgb(80, 158, 234)'
    }
};
