import { ethers, utils } from 'ethers';
import ethProvider from 'eth-provider';
// ENS resolution always happens on Ethereum mainnet
const eth = ethProvider('frame', { name: 'ens-resolver' });
eth.setChain('0x1');
export async function resolve(address) {
    const provider = new ethers.providers.Web3Provider(eth, 'any');
    const results = {
        ens: '',
        address: ''
    };
    if (utils.isAddress(address)) {
        try {
            results.ens = (await provider.lookupAddress(address)) || '';
        }
        catch (e) {
            console.log('could not resolve ENS name from address', e);
        }
        results.address = address;
    }
    else {
        results.address = (await provider.resolveName(address)) || '';
        results.ens = address;
    }
    return results;
}
