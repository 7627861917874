import provider from '../provider';
export async function fetchAccounts() {
    try {
        return await provider.request({ method: 'eth_accounts' });
    }
    catch (e) {
        console.error('Could not load accounts', e);
        return [];
    }
}
