import React, { useEffect, useRef } from 'react';
function inputSize(value, topend = 40) {
    const min = 16;
    const max = 26;
    if (value.length >= topend)
        return min + 'px';
    return max - Math.round((value.length / topend) * (max - min)) + 'px';
}
const InputBox = ({ value, maxTextSize = 40, hideText = false, onBeforeInput = () => { }, onChange = () => { } }) => {
    const ref = useRef(null);
    useEffect(() => {
        if (ref.current) {
            ref.current.focus({ preventScroll: true });
        }
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement("input", { value: value, style: {
                fontSize: inputSize(value, maxTextSize),
                opacity: hideText ? 0 : 1
            }, tabIndex: -1, ref: ref, type: "text", spellCheck: "false", onBeforeInput: onBeforeInput, onChange: onChange })));
};
export default InputBox;
