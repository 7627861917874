import { utils } from 'ethers';
// FIXME: why does this not compile when bundling?
// import log from '@framelabs/logger'
import provider from '../../js/provider';
const erc20abi = [
    'function balanceOf(address owner) view returns (uint256)',
    'function decimals() view returns (uint8)',
    'function symbol() view returns (string)',
    'function transfer(address to, uint amount) returns (bool)',
    'event Transfer(address indexed from, address indexed to, uint amount)'
];
const erc20Interface = new utils.Interface(erc20abi);
async function submit(txData) {
    const chainId = `0x${txData.chainId.toString(16)}`;
    if (txData.type === 'native') {
        const { gasLimit, gasPrice, maxFeePerGas, maxPriorityFeePerGas } = txData;
        const gasOptions = gasPrice
            ? { gasPrice, gasLimit }
            : { maxFeePerGas, maxPriorityFeePerGas, gasLimit };
        const tx = {
            to: txData.to,
            from: txData.from,
            chainId,
            value: txData.amount,
            ...gasOptions
        };
        console.log('Sending native currency transaction', tx);
        return provider.request({
            method: 'eth_sendTransaction',
            chainId,
            params: [tx]
        });
    }
    else if (txData.type === 'erc20') {
        const functionData = erc20Interface.encodeFunctionData('transfer', [
            txData.to,
            txData.amount
        ]);
        const contract = txData.contract;
        const tx = {
            to: contract,
            from: txData.from,
            value: '0x0',
            data: functionData,
            chainId
        };
        console.log('Sending ERC-20 transaction', tx);
        return provider.request({
            method: 'eth_sendTransaction',
            params: [tx],
            chainId
        });
    }
    else {
        throw new Error('Invalid Transaction Type');
    }
}
export { submit };
