import React from 'react';
import Restore from 'react-restore';
import Nav from '../Nav';
import Send from '../Send';
import Notification from '../Notification';
class _Main extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            searchValue: '',
            enteredRecipient: '',
            enteredAmount: ''
        };
    }
    render() {
        const { searchValue, enteredRecipient, enteredAmount } = this.state;
        const { isLoading, onRestart } = this.props;
        const showNav = !isLoading;
        const { reversing, currentStep } = this.store.getNav();
        const { message, error } = this.store.getNotify();
        const notificationMessage = error || message;
        const restartHandler = () => {
            this.store.restart();
            this.setState({
                searchValue: '',
                enteredRecipient: '',
                enteredAmount: ''
            });
            onRestart();
        };
        return (React.createElement(React.Fragment, null,
            React.createElement(Send, { searchValue: searchValue, enteredRecipient: enteredRecipient, enteredAmount: enteredAmount, setSearchValue: (searchValue) => this.setState({ searchValue }), setRecipient: (enteredRecipient) => this.setState({ enteredRecipient }), setAmount: (enteredAmount) => this.setState({ enteredAmount }), onRestart: onRestart }),
            notificationMessage && (React.createElement(Notification, { message: notificationMessage, isError: !!error })),
            showNav && (React.createElement(Nav, { currentStep: currentStep, reversing: reversing, advancing: this.store.isEnterKeyPressed(), onBack: () => this.store.reverse(), onForward: () => this.store.advance(), onRestart: restartHandler }))));
    }
}
const Main = Restore.connect(_Main);
export default Main;
