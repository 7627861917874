import { isHexString, getAddress } from 'ethers/lib/utils';
import { addHexPrefix } from 'ethereumjs-util';
export function validateRecipient(recipient) {
    if (!recipient) {
        return { err: 'Please enter recipient', recipient };
    }
    if (isHexString(addHexPrefix(recipient))) {
        // if a hex string is entered, attempt to normalize it to a checksummed address
        try {
            const normalizedAddress = getAddress(recipient);
            return { err: '', recipient: normalizedAddress };
        }
        catch (e) {
            console.warn('Invalid address entered', e);
            const reason = e.reason || 'invalid address';
            return { err: reason, recipient };
        }
    }
    if (!recipient.endsWith('.eth')) {
        return { err: 'Recipient must be address or ENS name', recipient };
    }
    return { err: '', recipient };
}
