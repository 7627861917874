import BigNumber from 'bignumber.js';
import provider from '../provider';
import valueFromPrice from './value';
import createBalance from './balance';
const jsonApi = {
    getAssets: async () => {
        return provider.request({ method: 'wallet_getAssets' });
    }
};
export function AssetsChangedListener(handler) {
    return function (event) {
        const account = event.account || '';
        const assets = toAssets(event);
        handler(account, assets);
    };
}
export async function loadAssets() {
    try {
        const response = await jsonApi.getAssets();
        return { loaded: true, assets: toAssets(response) };
    }
    catch (e) {
        const err = e;
        if (err.code === 5901) {
            // assets have not finished loading yet
            return { loaded: false, assets: [] };
        }
        console.error('Could not load assets', e);
        return { loaded: true, assets: [] };
    }
}
function parsePrice(assetPrice) {
    return {
        usd: 'usd' in assetPrice
            ? new BigNumber(assetPrice.usd.price)
            : new BigNumber(NaN)
    };
}
function toAssets(assets) {
    const allFungibles = [];
    console.log({ assets });
    assets.erc20.forEach((assetInfo) => {
        const { chainId, symbol, logoURI, name, address, decimals, balance: balanceHex, tokenInfo } = assetInfo;
        const price = parsePrice(tokenInfo.lastKnownPrice);
        const balance = createBalance(balanceHex, decimals, price);
        const value = valueFromPrice(balance.raw, decimals, price);
        const token = {
            type: 'erc20',
            name,
            contractAddress: address,
            decimals,
            symbol,
            logoURI,
            chainId,
            balance,
            price,
            value
        };
        allFungibles.push(token);
    });
    assets.nativeCurrency.forEach((assetInfo) => {
        const decimals = 18;
        const { chainId, symbol, balance: balanceHex, currencyInfo } = assetInfo;
        const { icon, name: currencyName } = currencyInfo;
        const price = parsePrice(currencyInfo);
        const balance = createBalance(balanceHex, decimals, price);
        const value = valueFromPrice(balance.raw, decimals, price);
        const currency = {
            type: 'native',
            name: currencyName,
            decimals,
            symbol,
            logoURI: icon,
            chainId,
            balance,
            price,
            value
        };
        allFungibles.push(currency);
    });
    allFungibles.sort((a, b) => b.value.total.comparedTo(a.value.total));
    return allFungibles;
}
