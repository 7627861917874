import React, { useState } from 'react';
const AccountComponent = ({ account: { address, ens } }) => {
    const [hover, setHover] = useState(false);
    const [copied, setCopied] = useState(false);
    const clippedAddress = !address
        ? ''
        : address.substr(0, 5) + '...' + address.substring(address.length - 3);
    return (React.createElement("div", { className: "headerAccount", onMouseEnter: () => setHover(true), onMouseLeave: () => setHover(false), onClick: () => {
            if (!!address) {
                navigator.clipboard.writeText(address);
                setCopied(true);
                setTimeout(() => {
                    setCopied(false);
                }, 1800);
            }
        } }, !address
        ? 'Connect Account'
        : copied
            ? 'Address Copied'
            : hover
                ? address
                : ens || clippedAddress));
};
export default AccountComponent;
