import React from 'react';
import Header from './components/Header';
import Main from './components/Main';
import Lift from './components/Lift';
class App extends React.Component {
    handleKeyPress(e) {
        if (e.code === 'Enter' || e.code === 'NumpadEnter') {
            this.store.enterKey(true);
        }
        else if (e.code === 'ArrowLeft' && (e.altKey || e.metaKey)) {
            e.preventDefault();
            e.stopPropagation();
            if (!this.isLoading())
                this.store.reverse();
        }
        else if (e.code === 'ArrowRight' && (e.altKey || e.metaKey)) {
            e.preventDefault();
            e.stopPropagation();
            if (!this.isLoading())
                this.store.advance();
        }
    }
    handleKeyUp(e) {
        if (e.code === 'Enter' || e.code === 'NumpadEnter') {
            if (!this.isLoading())
                this.store.advance();
            this.store.enterKey(false);
        }
    }
    isLoading() {
        const accountSelected = !!this.store.getCurrentAccount().address;
        return !accountSelected || this.store.isLoadingAssets();
    }
    componentDidMount() {
        document.addEventListener('keydown', this.handleKeyPress.bind(this));
        document.addEventListener('keyup', this.handleKeyUp.bind(this));
    }
    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyPress.bind(this));
        document.removeEventListener('keyup', this.handleKeyUp.bind(this));
    }
    render() {
        const failed = this.store.didTransactionFail();
        const succeeded = !!this.store.getTransactionHash();
        const currentStep = this.store.getCurrentStep();
        const currentAccount = this.store.getCurrentAccount();
        const isLoading = this.isLoading();
        const glitching = this.store.isGlitching();
        return (React.createElement("div", { id: "dashboard" },
            React.createElement("div", { className: "colorOverlay" }),
            React.createElement("div", { className: "wingWrap" }),
            React.createElement("div", { className: "vibeOverlay" }),
            React.createElement(Lift, { level: 1, stage: currentStep, failed: failed, succeeded: succeeded }),
            React.createElement(Lift, { level: 2, stage: currentStep, failed: failed, succeeded: succeeded }),
            React.createElement("div", { className: "loadIn", style: { opacity: this.store.isLoadedIn() ? 1 : 0 } },
                React.createElement(Header, { currentAccount: currentAccount, setGlitching: (active) => this.store.activateGlitch(active), isGlitching: glitching }),
                React.createElement(Main, { key: currentAccount.address, isLoading: isLoading, onRestart: window.refreshAssets }))));
    }
}
export default App;
