import React, { useEffect, useState } from 'react';
const Asset = ({ asset, outsideList, chainName, chainColor, onClick }) => {
    let hoverTimeout;
    const [hover, setHover] = useState(false);
    const [hoverCopied, setHoverCopied] = useState(false);
    const amountClickHandler = (e) => {
        e.preventDefault();
        e.stopPropagation();
        navigator.clipboard.writeText(asset.balance.formatted);
        setHoverCopied(true);
        hoverTimeout = setTimeout(() => {
            setHoverCopied(false);
        }, 1800);
    };
    useEffect(() => {
        return function () {
            if (hoverTimeout) {
                clearTimeout(hoverTimeout);
            }
        };
    }, []);
    const { name, logoURI, symbol = '' } = asset;
    const highlights = chainColor ? { color: chainColor } : {};
    const symbolAbbrev = symbol.slice(0, 4);
    const tokenClasses = ['tokenIcon']
        .concat(symbolAbbrev.length < 4 ? ['large'] : [])
        .join(' ');
    return (React.createElement("div", { role: "listitem", className: outsideList ? 'sendToken sendTokenOutsideList' : 'sendToken', onClick: onClick },
        React.createElement("div", { className: tokenClasses },
            React.createElement("img", { src: logoURI, alt: symbolAbbrev })),
        React.createElement("div", { className: "tokenChain", style: chainColor
                ? {
                    background: chainColor
                }
                : {} }),
        hover || hoverCopied ? null : (React.createElement("div", { className: "sendTokenInfo" },
            React.createElement("div", { className: "sendTokenName" }, name),
            React.createElement("div", { className: "sendTokenChain", style: highlights }, `On ${chainName}`))),
        React.createElement("div", { className: "sendTokenBalance" },
            React.createElement("div", { className: "sendTokenSymbol", style: highlights }, hoverCopied ? 'Amount Copied' : symbol),
            React.createElement("div", { className: "sendTokenAmount", onMouseOver: () => setHover(true), onMouseEnter: () => setHover(true), onMouseLeave: () => setHover(false), onClick: amountClickHandler }, hoverCopied
                ? ''
                : hover
                    ? asset.balance.formatted
                    : asset.balance.display)),
        React.createElement("div", { className: "sendTokenValue" }, asset.value.display)));
};
export default Asset;
