import React, { useEffect, useRef, useState } from 'react';
const STAR_LEVEL_DATA = {
    1: {
        count: 200,
        speeds: ['9600ms', '4800ms', '2400ms', '1200ms', '600ms']
    },
    2: {
        count: 80,
        speeds: ['19200ms', '9600ms', '4800ms', '2400ms', '1200ms']
    }
};
function createRandomStars(num, size) {
    return [...Array(num).keys()].map((i) => {
        return {
            x: Math.random(),
            y: Math.random(),
            size: Math.random() * 3 + size
        };
    });
}
function styleStar(star) {
    return {
        transform: `translate(${star.x}px, ${star.y}px)`,
        width: `${star.size}px`,
        height: `${star.size}px`,
        borderRadius: `${star.size / 2}px`,
        opacity: 0.08
    };
}
const Star = ({ good, bad, star }) => {
    return (React.createElement("div", { className: bad
            ? 'liftStar liftStarFail'
            : good
                ? 'liftStar liftStarSuccess'
                : 'liftStar', style: styleStar(star) }));
};
const Lift = ({ level, stage, failed, succeeded }) => {
    const doubleLift = useRef(null);
    const [stars, setStars] = useState([]);
    const { speeds } = STAR_LEVEL_DATA[level];
    const animationStage = failed || succeeded ? 0 : stage;
    const animationDuration = speeds[animationStage];
    useEffect(() => {
        const compute = () => {
            const { count } = STAR_LEVEL_DATA[level];
            const starStats = createRandomStars(count, level);
            const max = Math.max(document.documentElement.clientWidth, document.documentElement.clientHeight);
            const calculatedStars = starStats.map((s) => {
                return {
                    x: s.x * max,
                    y: s.y * max,
                    size: s.size
                };
            });
            setStars(calculatedStars);
        };
        compute();
        const observer = new ResizeObserver(compute);
        observer.observe(document.body);
        return () => {
            observer.disconnect();
        };
    }, []);
    const renderedStars = stars.map((s, i) => {
        return React.createElement(Star, { key: i, good: succeeded, bad: failed, star: s });
    });
    return (React.createElement("div", { className: "lift" },
        React.createElement("div", { className: "doubleLift", ref: doubleLift, style: { animationDuration } },
            React.createElement("div", { className: "liftInner" }, renderedStars),
            React.createElement("div", { className: "liftInner" }, renderedStars))));
};
export default Lift;
