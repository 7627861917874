import 'regenerator-runtime/runtime';
import { getAddress } from '@ethersproject/address';
import provider from './provider';
import store from '../store';
import { resolve } from './ens';
import { ChainsChangedListener, fetchChains } from './chains';
import { AssetsChangedListener, loadAssets } from './assets';
import { fetchAccounts } from './accounts';
async function resolveAccountEnsName(address) {
    try {
        const account = await resolve(address);
        store.setAccount(account);
    }
    catch (e) {
        console.warn('could not resolve ENS name for address', address);
    }
}
provider.on('close', () => {
    console.log('Ethereum provider closed');
    store.reset();
});
const chainsChangedHandler = (chains) => {
    console.log('CHAINS CHANGED', { chains });
    store.setChains(chains);
};
const assetsChangedHandler = (account, assets) => {
    const currentAccount = store.getCurrentAccount();
    console.log('ASSEST CHANGED', {
        account,
        currentAccount,
        assets,
        addr1: getAddress(account),
        addr2: currentAccount.address
    });
    if (getAddress(account) === currentAccount.address) {
        store.setBalances(assets);
        store.setLoadingAssets(false);
    }
};
const accountsChangedHandler = (accounts) => {
    const activeAccount = store.getCurrentAccount();
    console.log('ACCOUNTS CHANGED', { activeAccount, accounts });
    if (accounts.length === 0) {
        // if no account was selected and we get an empty accountsChanged, it could
        // be that an account that doesn't yet have permissions was opened, so attempt
        // to initialize with this account which will prompt for permissions in Frame
        start();
    }
    else {
        const selectedAccount = getAddress(accounts[0]);
        console.log({ selectedAccount });
        if (activeAccount.address !== selectedAccount) {
            initAccount(selectedAccount);
        }
    }
};
const assetsChangedListener = AssetsChangedListener(assetsChangedHandler);
const chainsChangedListener = ChainsChangedListener(chainsChangedHandler);
if (provider.connected) {
    setup();
}
provider.on('connect', (event) => {
    console.log(`Ethereum provider connected on chain ${parseInt(event.chainId, 16)}`);
    setup();
});
// initial setup, only called when app is first loaded
async function setup() {
    start();
}
// represents the state of the app when no account is selected
function start() {
    console.log('---- > START');
    provider.off('assetsChanged', assetsChangedListener);
    provider.off('accountsChanged', accountsChangedHandler);
    provider.off('chainsChanged', chainsChangedListener);
    store.clearAccount();
    store.restart();
    store.setBalances([]);
    const loadAccounts = async () => {
        const accounts = await fetchAccounts();
        if (accounts.length > 0) {
            initAccount(getAddress(accounts[0]));
        }
        provider.on('accountsChanged', accountsChangedHandler);
        provider.on('chainsChanged', chainsChangedListener);
    };
    loadAccounts();
}
async function initAccount(account) {
    resolveAccountEnsName(account);
    store.setAccount({ ens: '', address: account });
    store.restart();
    store.setBalances([]);
    store.setLoadingAssets(true);
    const loadChains = async () => {
        const { walletChainsEnabled, chains } = await fetchChains();
        console.log({ walletChainsEnabled, chains });
        store.setWalletChainsEnabled(walletChainsEnabled);
        store.setChains(chains);
    };
    loadChains();
    await refreshAssets();
    provider.on('assetsChanged', assetsChangedListener);
}
async function refreshAssets() {
    const { loaded, assets } = await loadAssets();
    if (loaded) {
        assetsChangedHandler(store.getCurrentAccount().address, assets);
    }
}
window.refreshAssets = refreshAssets;
window.onload = () => {
    document.body.className += ' loaded';
};
const runRandomGlitch = () => {
    setTimeout(() => {
        store.activateGlitch(true);
        setTimeout(() => {
            store.activateGlitch(false);
            runRandomGlitch();
        }, 1.6 * 1000 * Math.random() + 1000);
    }, 15 * 1000 * Math.random());
};
runRandomGlitch();
const loadingObserver = store.observer(() => {
    const currentAccount = store.getCurrentAccount();
    const accountSelected = !!currentAccount;
    const assetsLoaded = store.getBalances().length > 0;
    const loadingAssets = store.isLoadingAssets();
    const isLoading = !accountSelected || (!assetsLoaded && loadingAssets);
    if (!isLoading)
        store.setLoadedIn(true);
});
setTimeout(() => {
    store.setLoadedIn(true);
    loadingObserver.remove();
}, 2400);
