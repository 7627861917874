import BigNumber from 'bignumber.js';
import provider from '../../js/provider';
async function getLatestBlock() {
    return provider.request({
        method: 'eth_getBlockByNumber',
        params: ['latest', false]
    });
}
async function getGasPrice() {
    try {
        const price = await provider.request({ method: 'eth_gasPrice' });
        return new BigNumber(price, 16);
    }
    catch (e) {
        console.warn('could not fetch latest gas price', e);
    }
}
async function getFeeMarket(gasPrice) {
    try {
        const latestBlock = await getLatestBlock();
        if (latestBlock.baseFeePerGas) {
            const baseFeePerGas = new BigNumber(latestBlock.baseFeePerGas, 16);
            return {
                maxFeePerGas: gasPrice,
                maxPriorityFeePerGas: gasPrice.minus(baseFeePerGas)
            };
        }
    }
    catch (e) {
        console.warn('could not fetch latest block', e);
    }
}
export async function getGasFees() {
    const gas = {};
    const gasPrice = await getGasPrice();
    if (gasPrice) {
        const feeMarket = await getFeeMarket(gasPrice);
        if (feeMarket) {
            gas.fees = feeMarket;
        }
        else {
            gas.gasPrice = gasPrice;
        }
    }
    return gas;
}
