import { utils } from 'ethers';
import BigNumber from 'bignumber.js';
function displayBalance(balance, decimals, priceUSD = 0) {
    const formatted = utils.formatUnits(balance, decimals);
    const displayDec = Math.ceil(priceUSD).toString().length + 2;
    const digits = Math.ceil(parseFloat(formatted)).toString().length;
    const display = digits <= 3 ? 6 - digits : Math.max(displayDec - digits, 2);
    return utils.commify(parseFloat(formatted).toFixed(display));
}
export default function (balanceStr = '0x0', decimals, price) {
    const balance = new BigNumber(balanceStr, 16);
    const rawBalance = '0x' + balance.toString(16);
    return {
        raw: balance,
        formatted: utils.formatUnits(rawBalance, decimals),
        display: displayBalance(rawBalance, decimals, price.usd.toNumber())
    };
}
