let errorTimeout;
export const notifyError = (u, error = '', persist = false) => {
    clearTimeout(errorTimeout);
    u('notify.error', () => error);
    if (!persist) {
        errorTimeout = setTimeout(() => {
            u('notify.error', () => '');
        }, 5000);
    }
};
export const notifyMessage = (u, message) => {
    u('notify.message', () => message);
};
