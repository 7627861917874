import React from 'react';
import Glitch from '../Glitch';
import AccountComponent from '../../components/Account';
const Header = ({ isGlitching, setGlitching, currentAccount }) => {
    return (React.createElement("div", { className: "navPanel header" },
        React.createElement("div", { className: "headerLogo", onMouseEnter: () => setGlitching(true), onMouseOver: () => setGlitching(true), onMouseLeave: () => setGlitching(false) },
            React.createElement(Glitch, { text: "Send", isActive: isGlitching })),
        React.createElement(AccountComponent, { account: currentAccount })));
};
export default Header;
