export function setAdvancing(u, advancing) {
    u('nav.advancing', () => advancing);
}
export function setReversing(u, reversing) {
    u('nav.reversing', () => reversing);
}
export function advance(u) {
    u('nav', (nav) => ({
        ...nav,
        targetStep: nav.currentStep + 1
    }));
}
export function reverse(u) {
    u('nav', (nav) => ({
        ...nav,
        targetStep: nav.currentStep - 1
    }));
}
export function setResolvingRecipient(u, resolving) {
    u('nav.resolvingRecipient', () => resolving);
}
export function cancelNavigation(u) {
    u('nav', (nav) => ({
        ...nav,
        targetStep: nav.currentStep
    }));
}
export function navigateToStep(u, stepIndex) {
    u('nav', (nav) => ({
        ...nav,
        currentStep: stepIndex,
        targetStep: stepIndex
    }));
}
