import React, { Children, useEffect, useRef, useState } from 'react';
import svg from '../../../../../resource/svg';
import scrollbar from '../../scrollbar';
const scrollBarWidth = scrollbar.width;
let scrollSnapTimeout;
const NoMatch = () => React.createElement("div", { className: "noTokenMatch" }, "No Matching Token");
const Scroller = ({ itemHeight, selectedIndex, movingUp, movingDown, setIndex, children }) => {
    const tokenScroller = useRef(null);
    const tokenScollerInner = useRef(null);
    const [assetScroll, setAssetScroll] = useState(0);
    const numChildren = Children.count(children);
    const maxScroll = (numChildren - 1) * itemHeight;
    const percentPos = assetScroll
        ? Math.min(assetScroll, maxScroll) / maxScroll
        : selectedIndex / (numChildren - 1);
    const pixelPos = percentPos * 84;
    useEffect(() => {
        if (tokenScroller.current) {
            tokenScroller.current.scrollTop = selectedIndex * itemHeight;
        }
        return () => {
            if (scrollSnapTimeout) {
                clearTimeout(scrollSnapTimeout);
            }
        };
    }, [selectedIndex]);
    const tokenDisplay = numChildren > 0 ? children : React.createElement(NoMatch, null);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "tokenScroller" },
            React.createElement("div", { className: "vertKeyStatus" },
                React.createElement("div", { className: movingUp ? 'arrowKey arrowKeyActive' : 'arrowKey', style: { paddingBottom: '8px' }, onClick: () => setIndex(Math.max(0, selectedIndex - 1)) }, svg.caretUp(10)),
                React.createElement("div", { className: movingDown ? 'arrowKey arrowKeyActive' : 'arrowKey', style: { paddingBottom: '6px' }, onClick: () => setIndex(Math.min(numChildren - 1, selectedIndex + 1)) }, svg.caretDown(10))),
            React.createElement("div", { className: "scrollStatus" },
                React.createElement("div", { className: "scrollMarker", style: {
                        transform: `translateY(${pixelPos}px)`,
                        transition: '100ms linear transform'
                    } }))),
        React.createElement("div", { className: "sendTokensWrap", style: { height: '180px' } },
            React.createElement("div", null,
                React.createElement("div", { className: "selectedToken" },
                    React.createElement("div", { className: "selectedTokenText" }, " select ")),
                React.createElement("div", { className: "sendTokens", style: { width: `calc(100% + ${scrollBarWidth + 50}px)` }, ref: tokenScroller, onScroll: (e) => {
                        const target = e.target;
                        e.preventDefault();
                        e.stopPropagation();
                        setAssetScroll(target.scrollTop);
                        const highlightedIndex = Math.round(target.scrollTop / itemHeight);
                        if (highlightedIndex !== selectedIndex) {
                            setIndex(highlightedIndex);
                        }
                        clearTimeout(scrollSnapTimeout);
                        scrollSnapTimeout = setTimeout(() => {
                            target.scrollTop =
                                Math.round(target.scrollTop / itemHeight) * itemHeight;
                            setAssetScroll(0);
                        }, 100);
                    } },
                    React.createElement("div", { role: "list", className: "sendTokensFloat", style: {
                            transition: 'ease-out 0.1s transform',
                            transform: `translateY(-${assetScroll
                                ? Math.min(assetScroll, maxScroll)
                                : selectedIndex * itemHeight}px)`
                        } }, tokenDisplay),
                    React.createElement("div", { role: "list", "aria-hidden": "true", className: "sendTokensInner", ref: tokenScollerInner, style: { width: `calc(100% - ${scrollBarWidth + 50}px)` } }, tokenDisplay))),
            React.createElement("div", { className: "sendTokensFadeTop" }),
            React.createElement("div", { className: "sendTokensFadeBot" }))));
};
export default Scroller;
